<template>
  <section class="mb-16 pb-16">
    <div
      class="header-container header-quartos d-flex justify-center align-center"
    >
      <h1 class="text-md-h1 text-sm-h3 text-h4 header-text">APARTAMENTOS</h1>
    </div>

    <section class="conteudo-page mb-16" style="overflow-y: hidden">
      <v-row class="" no-gutters>
        <v-col
          class="item-info pa-2 pa-sm-8 pa-md-12 d-flex flex-column align-center justify-center"
        >
          <p class="pa-4 p-1 primary--text text-center">
            Venha criar uma atmosfera perfeita e se ligar no que é mais
            essencial: a paz, a harmonia e o aconchego. Aqui nossos hóspedes têm
            o privilégio de contemplar a revoada das araras, tucanos e maritacas
            proporcionando enorme bem-estar e paz interior. Rico em detalhes,
            hospedar-se no Hotel Roda D´Água é uma experiência encantadora.
            Estamos abertos desde 2002, mas em constante renovação para
            oferecermos o que há de melhor. Venha...ADORAREMOS A HONRA DE SUA
            PRESENÇA!
          </p>
          <p class="pa-8 pt-0 p-1 primary--text text-center">
            Todos os nossos apartamentos são equipados com wi-fi gratuito, ar
            condicionado Split, ventilador de teto, TV de LED, camas box,
            enxoval de primeira qualidade, edredom, secador de cabelo e
            amenities. Algumas comodidades ainda contam com isolamento acústico
            nas portas e janelas.
          </p>
          <v-btn class="item-cta mt-4" color="primary" dark x-large tile @click="abrirWhats()"
            >FAÇA SUA RESERVA</v-btn
          >
        </v-col>
      </v-row>

      <v-row no-gutters class="mt-8 mb-8">
        <v-col class="col-12 d-flex flex-column align-center">
          <v-img class="preapend-header" src="../assets/tipografia.png"></v-img>

          <h2
            class="
              item-header
              section-header
              text-md-h2 text-h3 text-center
              primary--text
            "
          >
            Nossos quartos
          </h2>

          <h2
            class="
              text-md-h5 text-h6 text-center
              primary--text
            "
          >
            Escolha entre nossas diversas opções
          </h2>
        </v-col>
      </v-row>

      <v-row class="pb-16 mt-4" style="overflow-y: visible" no-gutters>
        
        <!-- ======== SELETOR DE QUARTOS PARA MOBILE ======== -->
        <v-col class="col-12 d-flex d-md-none align-center">
          <v-overflow-btn
            class="mt-2"
            @change="selecionarQuarto"
            v-model="selecionado"
            :items="tiposQuarto"
            item-text="titulo"
            filled
            item-value="value"
          ></v-overflow-btn>
        </v-col>
        <!-- ======== SELETOR DE QUARTOS desktop ======== -->
        <v-col class="col-7 pl-12 mb-8 d-md-flex d-none align-center">
          <ul
            class="d-flex flex-column justify-space-around"
            style="min-height: 70%; align-self: self-start;"
          >
            <li
            v-for="(quarto, idx) in tiposQuarto"
            :key="`quarto-${idx}`"
              class="text-h4 item-quarto"
              @click="selecionarQuarto(quarto.value)"
            >
              {{quarto.titulo}}
            </li>
          </ul>
        </v-col>



        <v-col
          class="
            section-imgs
            item-foto
            d-flex
            flex-column
            wrap-md-reverse
            quartos-carrosel
          "
          style="position: relative; flex-grow: 1; width: 50%; "
        >

          <v-carousel
            v-model="model"
            cycle
            hide-delimiter-background
            show-arrows-on-hover
            delimiter-icon="mdi-minus"
            height="600"
          >
            <template v-slot:prev="{ on, attrs }">
              <div class="primary pa-3 arrow-slider" v-bind="attrs" v-on="on" v-show="imgsSlider.length > 1">
                  <v-icon>mdi-arrow-left</v-icon>
                </div>
            </template>
            <template v-slot:next="{ on, attrs }">
              <div class="primary pa-3 arrow-slider arrow-right" v-bind="attrs" v-on="on" v-show="imgsSlider.length > 1"
                >
                  <v-icon>mdi-arrow-right</v-icon>
                </div>
            </template>
  
            <v-carousel-item
            active-class="slider-ativo"
              class="imgs mb-16 kenburns-left "
              v-for="(img, i) in imgsSlider"
              :key="`slider-${i}`"
            >
                  <v-img :src="img" class="imgs mb-16"/>
                  <!-- <div class="text-h2">Slide {{ i + 1 }}</div> -->
            </v-carousel-item>
          </v-carousel>

          <div
            class="
              info-quarto-container
              d-flex
              flex-column
              flex-md-row
              justify-space-around
              align-center
              pa-6
            "
          >
            <div
              v-for="prop in quartoSelecionado"
              :key="prop.icon"
              class="comodidades-container d-flex align-center"
            >
              <EscolhedorSVG
                class="comodidade-icon mr-2"
                :icon="prop.icon"
              ></EscolhedorSVG>
              <div class="comodidade-titulo p-2 text--white">
                {{ prop.desc }}
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </section>
  </section>
</template>

<style lang="scss">
.info-quarto-container {
  min-height: 164px;
  position: relative;
  z-index: 5;
  background-color: var(--v-primary-base);
  .comodidades-container {
    margin-left: 8px;
  }
  .escolher-svg {
    svg {
      width: 72px;
      height: 72px;
    }
  }
}
</style>


<script>
import EscolhedorSVG from "../components/EscolhedorSVG.vue";
export default {
  name: "Quartos",
    metaInfo: {
      title: 'Apartamentos',
      titleTemplate: `%s | Roda d'Água`,
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        {
          property: 'og:title',
          content: "Apartamentos",
          template: `%s | Roda d'Água`,
          vmid: 'og:title'
        }
      ],
    link: [
      {rel: 'canonical', href: 'https://www.hotelrodadagua.com.br/quartos'}
    ]
    },
  components: {
    EscolhedorSVG,
  },
  data: () => {
    return {
      model: 0,
      tiposQuarto: [
        {
          value: 'ap_familia',
          titulo: 'Suíte família'
        },
        {
          value: 'ap_quadruplo',
          titulo: 'Suíte Família Quádruplo'
        },
        {
          value: 'triplo_familia',
          titulo: 'Apartamento Triplo Família'
        },
        {
          value: 'ap_superior',
          titulo: 'Apartamento Superior'
        },
        {
          value: 'ap_conjugado',
          titulo: 'Apartamento Conjugado'
        },
        {
          value: 'ap_quadruplo',
          titulo: 'Apartamento Quádruplo/Quíntuplo'
        },
        {
          value: 'suite_hidro',
          titulo: 'Suíte hidromassagem'
        },
      ],
      selecionado: "ap_familia",
      ap_familia: [
        {
          desc: "Até 3 pessoas",
          icon: "pessoa3",
        },
        {
          desc: "1 Cama queen size",
          icon: "double",
        },
        {
          desc: `1 Cama de solteiro`,
          icon: "single",
        },
      ],

      ap_superior: [
        {
          desc: "Até 2 pessoas",
          icon: "pessoa2",
        },
        {
          desc: "1 Cama queen size",
          icon: "double",
        },
      ],

      ap_quadruplo: [
        {
          desc: "Até 5 pessoas",
          icon: "pessoa5",
        },
        {
          desc: "1 Cama queen size",
          icon: "double",
        },
        {
          desc: "3 Camas de solteiro",
          icon: "single",
        },
      ],

      triplo_familia: [
        {
          desc: "Até 3 pessoas",
          icon: "pessoa3",
        },
        {
          desc: "1 Cama de casal",
          icon: "double",
        },
        {
          desc: "1 Cama de solteiro",
          icon: "single",
        },
      ],

      ap_conjugado: [
        {
          desc: "Até 5 pessoas",
          icon: "pessoa5",
        },
        {
          desc: "1 Cama de casal",
          icon: "double",
        },
        {
          desc: "3 Cama de solteiro",
          icon: "single",
        },
      ],

      suite_hidro: [
        {
          desc: "Até 2 pessoas",
          icon: "pessoa2",
        },
        {
          desc: "1 Cama king size",
          icon: "double",
        },
      ],
      pathImgs: [
        'restaurante.jpg',
        'restaurante.jpg',
        'restaurante.jpg',
      ],
      imgsSlider: [
      ]
    };
  },
  async created(){
    this.carregarImg('ap_familia')
    this.$emit('carregado')
  }, 
  methods: {
    
    abrirWhats(){
      window.open("https://wa.me/5518996992228");
    },
    selecionarQuarto(quarto) {
      if(typeof quarto !== 'string') quarto = quarto.value
      this.carregarImg(quarto)
      this.selecionado = quarto;
    },
    carregarImg(quarto){
      const imgs = {
        ap_familia: [`suitefamilia1`, `suitefamilia2`],
        ap_superior: ['superior1'],
        ap_quadruplo: ['quadruplo1', 'quadruplo2'],
        triplo_familia: ['triplo-familia-1'],
        ap_conjugado: ['conjugado2', 'conjugado1'],
        suite_hidro: ['hidro1', 'hidro2', 'hidro3'],
      }
      this.imgsSlider = []
      imgs[quarto].forEach(path => {
          const img = require(`../assets/quartos/${path}.jpg`)
        this.imgsSlider.push(img)
      })
    }
  },
  computed: {
    quartoSelecionado() {
      return this[this.selecionado];
    },
  },
};
</script>